var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DnDZone',{staticClass:"full-height",on:{"files-dropped":_vm.onFilesDroppped}},[_c('v-container',{staticClass:"full-height"},[_c('class-viewer-list',{ref:"list",staticClass:"full-width",attrs:{"title":"","class-id":_vm.classId,"delete-action":"","confirm-on-delete":""},scopedSlots:_vm._u([{key:"header-buttons",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onAdd()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-upload ")]),_vm._v(" Importer ")],1)]},proxy:true},{key:"name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{staticClass:"source-name",on:{"click":function($event){return _vm.toggleOpenPreview(item)}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","to":("/importfile/templates/new?fileId=" + (item.kid))}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-code-braces ")])],1)]}}],null,true)},[_c('span',[_vm._v("Créer un template")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }