



























































import { Component, Vue } from 'vue-property-decorator'
import { Pane, Splitpanes } from 'splitpanes'
import { ObjectListObject, SharedConstants } from '@knitiv/api-client-javascript'
import ClassViewerList from '@/components/importfile/class-viewer-list.vue'
import DnDZone from '@/components/dnd-zone.vue'

import ImportDatasourceDialog from '@/components/importfile/modal-import-datasource.vue'
import ModalPreviewExcel from '@/components/importfile/viewer/modal-preview-excel.vue'
import { InjectAPI } from '@/utils/api'

type enhancedObject = ObjectListObject & { kid: string }

@Component({
  components: {
    Splitpanes,
    Pane,
    ClassViewerList,
    DnDZone,
  },
  mixins: [InjectAPI],
})
export default class DataSourcesList extends Vue {
  classId = SharedConstants.nodes.DATAEXCH_FILE

  async toggleOpenPreview(item: enhancedObject) {
    console.log('item', item)

    const answer = await this.$dialog.open(ModalPreviewExcel, {
      maxWidth: '80%',
      component: {
        kid: item.kid,
      },
    })
    console.log('answer', answer)
  }

  onFilesDroppped(files: File[]) {
    this.onAdd(files[0])
  }

  async onAdd(file?: File) {
    const answer = await this.$dialog.open(ImportDatasourceDialog, {
      component: {
        file,
      },
    })
    if (answer !== 'close') {
      console.log('answer', answer)
      // @ts-ignore
      this.$refs.list.refresh()
    }
  }
}
