



































import Vue, { PropType } from 'vue'
import ExcelPreview from '@/components/importfile/excel-preview.vue'

export default Vue.extend({
  name: 'Report',
  components: {
    ExcelPreview,
  },
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    kid: {
      type: String,
      required: true,
    },
  },
})
