













































































import Vue, { PropType } from 'vue'
import {
  FileUpload, ObjectCreateItem, ObjectCreateOutput, SharedConstants,
} from '@knitiv/api-client-javascript'
import { InjectAPI } from '@/utils/api'

function* getNextNR(): IterableIterator<string> {
  let counter = 1

  while (true) {
    yield `K_NODE;_NR_${counter.toString().padStart(6, '0')}`
    counter += 1
  }
}

interface Data {
  importName: string
  uploadPercent: number
  isUploading: boolean
  uploadedNode: string
  selectedFile?: File
  isDone: boolean
  isValidating: boolean
  template: any[]
  mfOrder: string
}

export default Vue.extend({
  components: {},
  mixins: [
    InjectAPI,
  ],
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    file: {
      type: File,
      required: false,
      default: undefined,
    },
  },
  data(): Data {
    return {
      importName: '',
      uploadPercent: 0,
      isUploading: false,
      uploadedNode: '',
      selectedFile: this.file,
      isDone: false,
      isValidating: false,
      template: [],
      mfOrder: SharedConstants.nodes.DATAEXCH_TEMPLATE,
    }
  },
  methods: {
    resetModal() {
      this.importName = ''
      this.uploadPercent = 0
      this.isUploading = false
      this.uploadedNode = ''
      this.selectedFile = this.file
      this.isDone = false
      this.isValidating = false
    },

    async checkIfNameIsTaken() {
      if (!this.importName) {
        throw new Error('Import file name not specified')
      }

      let result: boolean

      this.isValidating = true

      try {
        const response = await this.$api.objectExist({ objectClass: SharedConstants.nodes.DATAEXCH_FILE, name: this.importName, links: [] })
        console.log('response', response)

        console.log('response.RESULT_COUNT', response.result.RESULT_COUNT)
        if (response.result.RESULT_COUNT === 0) {
          result = false
        } else {
          this.$toast.error('Ce nom est déjà utilisé !')
          result = true
        }
      } catch (e) {
        this.$toast('Une erreur est survenue lors de la vérification du nom')
        result = true
        console.error(e)
      }

      this.isValidating = false
      return result
    },

    async uploadFile(): Promise<ObjectCreateOutput | null> {
      if (!this.selectedFile) {
        throw new Error('No file selected')
      }

      const [, extension] = this.selectedFile.name.split('.')

      const buffer = this.selectedFile as Blob

      const fileUpload: FileUpload = {
        extension: `.${extension}`,
        size: this.selectedFile.size,
        name: this.importName,
        buffer,
      }

      const { filename, id } = await this.$api.upload({
        file: fileUpload,
        onProgress: (progress) => {
          console.log('progress', progress)
          this.uploadPercent = progress.progress
        },
      })

      const nr: string = getNextNR().next().value

      const item: ObjectCreateItem = {
        target_objnum: nr,
        OBJECT_LIST: {
          [nr]: {
            isa: SharedConstants.nodes.DATAEXCH_FILE,
            fileid: id,
            REPRE_LIST: [
              {
                name: filename,
                lang: SharedConstants.representations.UNIVERSEL,
              },
            ],
          },
        },
      }
      return this.$api.objectCreate({
        items: [item],
      })
    },

    async importFile() {
      try {
        const taken = await this.checkIfNameIsTaken()

        if (taken) {
          return
        }

        this.uploadPercent = 0

        this.isUploading = true
        const upload = await this.uploadFile()
        this.isUploading = false
        this.isDone = true
        if (upload) {
          const objIds = Object.values(upload.result)
          this.uploadedNode = objIds[0].objid // We only have 1 anyway
        }
        this.resolve(this.uploadedNode)
      } catch (e) {
        this.resolve('closed')
        console.error(e)
      }
    },
  },
})
